<template>
  <div>
    <div class="footer-sub-menu bg-white">
      <b-row
        class="justify-content-between text-center no-gutters text-green-primary h-100 mt-sm-3 mt-2"
      >
        <b-col cols="2 " v-for="(item, index) in navList" :key="index">
          <font-awesome-icon class="icon-w" :icon="item.icon" />
          <div class="f-12">{{ item.text }}</div>
        </b-col>
      </b-row>
    </div>
  </div>
</template>

<script>
export default {
  components: {},
  data: () => {
    return {
      navList: [
        { text: "คำนวณค่างวด", tabIndex: 0, icon: ["fas", "calculator"] },
        { text: "กิจกรรมของฉัน", tabIndex: 1, icon: ["fas", "ticket"] },
        { text: "SKL แฟมิลี่คลับ", tabIndex: 2, icon: ["fas", "gift"] },
        { text: "SKL ครัวเรือน", tabIndex: 3, icon: ["fas", "book"] },
        { text: "ตั้งค่า", tabIndex: 4, icon: ["fas", "gear"] }
      ]
    };
  }
};
</script>

<style scoped>
.footer-sub-menu {
  border-top: 1px solid rgba(0, 0, 0, 0.125);
  position: fixed;
  left: 0;
  bottom: 0;
  width: 100%;
  height: 80px !important;
  max-height: 80px ;
  background-color: red;

  text-align: center;
}
</style>
