<template>
  <div>
    <div class="loading-alpha" v-bind:class="{ active: $isLoadingAlpha }">
      <b-spinner label="Loading..." class="m-5 spinner-main"></b-spinner>
    </div>

    <div class="" :class="{ 'container-lg': $route.name !== 'Register' }">

      <router-view />
    </div>
    <NavFooter
      v-if="$route.name == 'Installment'"
      ref="navFooter"
      class="h-100"
      id="navFooter"
    ></NavFooter>
    <TryagainModel />
  </div>
</template>

<script>
const TryagainModel = () => import("@/components/Tryagain");
import NavFooter from "@/components/NavFooter";

export default {
  name: "App",
  components: {
    TryagainModel,
    NavFooter
  },
  data: () => ({}),

  beforeCreate() {
    /////////////////////////////////// Decode function ///////////////////////////////////////////////////
    // console.log(
    //   "decodeURIComponent",
    //   window.location,
    //   decodeURIComponent(window.location.search),
    //   window.location.href.includes("?liff.state="),
    //   window.location.href.includes("/?liff.state=")
    // );
    // if (decodeURIComponent(window.location.href).includes("liff.state=")) {
    //   if (decodeURIComponent(window.location.href).includes("/?liff.state=")) {
    //     // console.log(1);
    //     // console.log(
    //     //   decodeURIComponent(window.location.href).replace("/?liff.state=", "")
    //     // );
    //     window.location.assign(
    //       decodeURIComponent(window.location.href).replace("/?liff.state=", "")
    //     );
    //   }
    //   if (decodeURIComponent(window.location.href).includes("&liff.state=?")) {
    //     // console.log(2);
    //     // console.log(
    //     //   decodeURIComponent(window.location.href).replace("&liff.state=?", "&")
    //     // );
    //     window.location.assign(
    //       decodeURIComponent(window.location.href).replace("&liff.state=?", "&")
    //     );
    //   }
    //   if (decodeURIComponent(window.location.href).includes("&liff.state=/")) {
    //     // console.log(3);
    //     // console.log(
    //     //   window.location.origin +
    //     //     window.location.pathname +
    //     //     decodeURIComponent(window.location.search).split(
    //     //       "&liff.state=/"
    //     //     )[1] +
    //     //     "&" +
    //     //     decodeURIComponent(window.location.search)
    //     //       .split("&liff.state=")[0]
    //     //       .replace("?", "")
    //     // );
    //     window.location.assign(
    //       window.location.origin +
    //         window.location.pathname +
    //         decodeURIComponent(window.location.search).split(
    //           "&liff.state=/"
    //         )[1] +
    //         "&" +
    //         decodeURIComponent(window.location.search)
    //           .split("&liff.state=")[0]
    //           .replace("?", "")
    //     );
    //   }
    // }
    //////////////////////////////////////////////////////////////////////////////////////
    // document.write(
    //   '<link rel="stylesheet" href="style.css?cache=' +
    //     new Date().getTime() +
    //     '">'
    // );
    // document.write(
    //   /* eslint-disable-next-line */
    //   `<script defer src="app.js?cache=${new Date().getTime()}"><\/script>`
    // );
    // if (this.$liff.isInClient()) {
    //   const urlParams = new URLSearchParams(window.location.search);
    //   const myParam = urlParams.get("cacheW");
    //   if (!myParam) {
    //     var separator = window.location.href.indexOf("?") === -1 ? "?" : "&";
    //     window.location.href =
    //       window.location.href + separator + "cacheW=" + new Date().getTime();
    //   }
    // }
    // var url = window.location.href,
    //   separator = url.indexOf("?") === -1 ? "?" : "&",
    //   newParam = separator + "cache=" + new Date().getTime();
    // var newUrl = url.replace(newParam, "");
    // newUrl += newParam;
    // window.location.href = newUrl;
  },
  methods: {}
};
</script>
<style scoped>
.loading-alpha {
  width: 100%;
  height: 800px;
  position: relative;
  z-index: 1000;
  margin: auto;
  display: none;
  top: 0;
  -ms-flex-align: center !important;
  -webkit-box-align: center !important;
  align-items: center !important;
  -ms-flex-pack: center !important;
  -webkit-box-pack: center !important;
  justify-content: center !important;
}

.loading-alpha.active {
  display: flex;
}
.loading-alpha {
  position: fixed;
  background-color: rgba(255, 255, 255, 0.5);
  height: calc(100vh);
  z-index: 1400;
}

::v-deep .spinner-border {
  color: #84bc40;
}
</style>
