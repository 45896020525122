import Vue from "vue";
import VueRouter from "vue-router";
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";

const Home = () => import("../views/Home.vue");
const Register = () => import("../views/Register/Register.vue");
const Setting = () => import("../views/Setting.vue");
const DisconnectAccount = () => import("../views/DisconnectAccount.vue");
const SendDocument = () => import("../views/SendDocument.vue");
const Payment = () => import("../views/Payment.vue");
const Barcode = () => import("../views/Barcode.vue");
const Detail = () => import("../views/Detail.vue");
const Promotion = () => import("../views/Redirect.vue");
const Redirect = () => import("../views/Redirect.vue");
const Broadcast = () => import("../views/Broadcast.vue");

//Phase2
const BranchSearch = () => import("../views/BranchList.vue");
const Map = () => import("../views/Map.vue");
const FeedBack = () => import("../views/FeedBack.vue");
const RentalRequestStatus = () =>
  import("../views/RentalRequestStatus/RentalRequestStatus.vue");
const InstallmentList = () => import("../views/List/ListInstallment.vue");
const InstallmentHistory = () => import("../views/List/components/InstallmentHistory.vue");
const SKLFamily = () => import("../views/SKLFamily.vue");
const Point = () => import("../views/Point.vue");
const Tracking = () => import("../views/Tracking.vue");
const Policy = () => import("../views/Policy.vue");

Vue.use(VueRouter);


export const registerComponents = app => {

  app.component("font-awesome-icon", FontAwesomeIcon);
}

const routes = [
  {
    path: "/",
    name: "Home",
    component: Home,
    meta: {
      title: "Home"
    }
  },
  {
    path: "/home",
    name: "Home",
    component: Home,
    meta: {
      title: "Home"
    }
  },
  {
    path: "/register",
    name: "Register",
    component: Register,
    meta: {
      title: "SKL Connect - ลงทะเบียน"
    }
  },
  {
    path: "/payment",
    name: "Payment",
    component: Payment,
    meta: {
      title: "ชำระเงินออนไลน์"
    }
  },
  {
    path: "/setting",
    name: "Setting",
    component: Setting,
    meta: {
      title: "การตั้งค่า"
    }
  },
  {
    path: "/disconnect-account",
    name: "DisconnectAccount",
    component: DisconnectAccount,
    meta: {
      title: "ยกเลิกการลงทะเบียน"
    }
  },
  {
    path: "/send-document",
    name: "SendDocument",
    component: SendDocument,
    meta: {
      title: "แนบข้อมูลเอกสาร"
    }
  },
  {
    path: "/barcode",
    name: "Barcode",
    component: Barcode,
    meta: {
      title: "บาร์โคด/QR Code"
    }
  },
  {
    path: "/detail",
    name: "Detail",
    component: Detail,
    meta: {
      title: "รายละเอียด"
    }
  },
  {
    path: "/promotion",
    name: "Promotion",
    component: Promotion,
    meta: {
      title: "โปรโมชั่น"
    }
  },

  //Phase 2
  {
    path: "/branch-search",
    name: "BranchSearch",
    component: BranchSearch,
    meta: {
      title: "ค้นหาสาขาใกล้คุณ"
    }
  },
  {
    path: "/map",
    name: "Map",
    component: Map,
    meta: {
      title: "ที่ตั้งสาขา"
    }
  },
  {
    path: "/feedback",
    name: "FeedBack",
    component: FeedBack,
    meta: {
      title: "แนะนำติชมบริการ"
    }
  },
  {
    path: "/rentalRequestStatus",
    name: "RentalRequestStatus",
    component: RentalRequestStatus,
    meta: {
      title: "ตรวจสอบสถานะคำขอเช่าซื้อออนไลน์"
    }
  },
  {
    path: "/redirect",
    name: "Redirect",
    component: Redirect,
    meta: {
      title: "Redirect"
    }
  },
  {
    path: "/installment",
    name: "Installment",
    component: InstallmentList,
    meta: {
      title: "SKL Connect - ตารางค่างวด"
    }
  },
  {
    path: "/paymentHistory",
    name: "PaymentHistory",
    component: InstallmentHistory,
    meta: {
      title: "ประวัติการชำระ"
    }
  },
  {
    path: "/track",
    name: "tracking",
    component: Tracking,
    meta: {
      title: "สยามคูโบต้า ลีสซิ่ง (SKL)"
    }
  },
  {
    path: "/point",
    name: "point",
    component: Point,
    meta: {
      title: "สยามคูโบต้า ลีสซิ่ง (SKL)"
    }
  },
  {
    path: "/point/:LineId",
    name: "point",
    component: Point,
    meta: {
      title: "สยามคูโบต้า ลีสซิ่ง (SKL)"
    }
  },
  {
    path: "/SKLFamily",
    name: "sklFamily",
    component: SKLFamily,
    meta: {
      title: "สยามคูโบต้า ลีสซิ่ง (SKL)"
    }
  },
  {
    path: "/policy",
    name: "policy",
    component: Policy,
    meta: {
      title: "ข้อกำหนดและเงื่อนไข"
    }
  },
  {
    path: "/broadcast",
    name: "broadcast",
    component: Broadcast,
    meta: {
      title: "ข้อกำหนดและเงื่อนไข"
    }
  }
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes
});

router.beforeEach((to, from, next) => {
  document.title = to.meta.title;
  next();
});

export default router;
